<template>
  <dialog-window
    title="Отключение СМС уведомлений"
    show
    hide-footer
    max-width="480"
    @close="$emit('close')"
  >
    <p>
      При выключении этой опции вы перестанете получать уведомления о новых заказах по вашему
      поиску. Продолжить?
    </p>
    <custom-button
      round
      large
      class="ml-0"
      @click.stop="
        () => {
          $emit('submit');
          $emit('close');
        }
      "
    >
      Продолжить
    </custom-button>
    <custom-button round flat color="primary" @click.stop="$emit('close')">
      Отмена
    </custom-button>
  </dialog-window>
</template>

<script>
import DialogWindow from 'common/components/Dialog';
export default {
  name: 'DisableSmsNotificationModal',
  components: {
    DialogWindow
  }
};
</script>
